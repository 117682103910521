// If you want to override variables do it here
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
// @import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "../Components/sidebar/sidebar.scss";
// @import "example";

// If you want to add custom CSS you can put it here.
@import "custom";


.greenheading{
    color: #82D051;
}