.header.header-sticky{
  background-color: #f8f8f8;
  padding: 0 !important;
  border-bottom: none;
}
.navbar {
  display: flex;
  height: 88px;
  align-items: center;
  font-size: 14px;
  color: #555;
  background-color: #f8f8f8;
  width: 100%;
  padding: 0 !important;
  height: auto;
  .container-fluid{
    padding: 0 !important;
  }
  .wrapper_navbar {
    width: 100%;
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 18px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        // .counter {
        //   width: 15px;
        //   height: 15px;
        //   background-color: red;
        //   border-radius: 50%;
        //   color: white;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   font-size: 10px;
        //   font-weight: bold;
        //   position: absolute;
        //   top: -5px;
        //   right: -5px;
        // }
      }

      .item:last-child {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        border-left: 0.25px solid #898A8D;

        .icon {
          font-size: 18px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-left: 20px;
        }
        .text_topbar{
          margin-left: 8px;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          text-transform: capitalize;
          color: #898A8D;
        }
        .nav-item.dropdown{
          list-style-type: none;
        }
      }
    }
  }
}
