$sidebar-color:  #898A8D;
$sidebar-bg:  #fff;
$sidebar-width: 275px;
// $sidebar-padding-x: 20px;
$sidebar-brand-height: 88px;
$sidebar-nav-link-color: #9C9C9C;
$sidebar-nav-link-hover-color: #82D051;

.sidebar {
  --cui-sidebar-width: #{$sidebar-width};
  --cui-sidebar-bg: #{$sidebar-bg};
  --cui-sidebar-color: #{$sidebar-color};
  // --cui-sidebar-padding-x: #{$sidebar-padding-x};
  --cui-sidebar-brand-bg: #{$sidebar-bg};
  --cui-sidebar-brand-height: #{$sidebar-brand-height};
  --cui-sidebar-nav-link-color:  #{$sidebar-nav-link-color};
  --cui-sidebar-nav-link-hover-color: #{$sidebar-nav-link-hover-color};
  --cui-sidebar-nav-link-active-bg: #{$sidebar-nav-link-hover-color};
  --cui-header-border-color: none;
  .sidebar-brand{
    // 
    border-right: 3px solid #82D051;
    // .sidebar-brand-full{
      // padding: 0px 40px;
        // img{
        //   width: 100%;
        // }
    // }
  }
  .sidebar-nav{

    margin-top: 36px;
    
    .nav-item:first-child{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-transform: capitalize;
        color: #898A8D;
      }

    .nav-item {
      margin: 10px 20px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      
      a{
        padding-left: 8%;
      }
    }
    
    .nav-link:hover{
      svg{
        path{
          fill:#82D051;
        }
      }
    }

    .nav-link.active {
      background: var(--cui-sidebar-nav-link-active-bg);
      border-radius: 15px;

      svg{
        path{
          fill:#fff;
        }
      }
    }
    .nav-link.active:hover {
      color: #fff;
    }
  }
  

}

.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: #{$sidebar-width};
}

// div ul:not(:first-child) {
//   background-color: #900;
// }